import "./coupon.css";
import union from "../asset/img/union.svg";
import Carousel from "../carousel/carousel";

const Subject = () => {
  return (
    <div className="Coupon">
      <div className="coupon-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">
          LỄ KÝ KẾT HỢP TÁC HỆ THỐNG 500AE & SHBET
          </span>
        </div>
        <div class="coupon-body">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default Subject;
