import "./birthday.css";
import union from "../asset/img/union.svg";
import ok1 from "../asset/vid/OK1.mp4";
import ok2 from "../asset/vid/OK2.mp4";

const Birthday = () => {
  return (
    <div className="Birthday">
      <div className="birthday-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">Mừng sinh nhật 500AE - SHBET88</span>
        </div>
        <div className="birthday-body">
          <div className="birthday-item">
            <video autoPlay loop controls muted className="birthday-vid-item">
              <source src={ok1} type="video/mp4" />
            </video>
            <video autoPlay loop controls muted className="birthday-vid-item">
              <source src={ok2} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Birthday;
