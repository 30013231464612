import "./proof.css";
import { useState, useRef } from "react";
import union from "../asset/img/union.svg";
import ok1 from "../asset/img/proof1.webp";
import ok2 from "../asset/img/proof2.webp";
import ok3 from "../asset/img/proof3.webp";
import ok4 from "../asset/img/proof4.webp";
import ModalImg from "../modalImg/ModalImg";

const Subject = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleItemClick = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoUrl(""); // Reset videoUrl when closing modal
  };

  const videoRef = useRef(null);
  const imgRef = useRef(null);

  const handleVideoClick = () => {
    if (imgRef.current) {
      imgRef.current.style.display = "none"; // Ẩn hình ảnh đại diện
    }
    if (videoRef.current) {
      videoRef.current.style.display = "block"; // Hiển thị video
    }
  };

  return (
    <div className="Proof">
      <div className="proof-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">
            TRAO GIẢI CHO THÀNH VIÊN THAM GIA HỆ THỐNG 500AE
          </span>
        </div>
        <div className="proof-body">
          <div className="proofItems">
            <div className="proof-row1">
              <div className="proof-img" onClick={() => handleItemClick(ok1)}>
                <img src={ok1} />
              </div>
              <div className="proof-img" onClick={() => handleItemClick(ok2)}>
                <img src={ok2} />
              </div>
              <div className="proof-img" onClick={() => handleItemClick(ok3)}>
                <img src={ok3} />
              </div>
              <div className="proof-img" onClick={() => handleItemClick(ok4)}>
                <img src={ok4} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen &&
        (console.log("Video URL:", videoUrl),
        (<ModalImg videoUrl={videoUrl} closeModal={closeModal} />))}
    </div>
  );
};

export default Subject;
