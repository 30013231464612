import "./banner.css";
import React, { useRef, useState, useEffect } from "react";
import popup1 from "../asset/vid/1.gif";
import popup2 from "../asset/vid/2.gif";
import vid1 from "../asset/vid/OK.mp4";


const Banner = () => {
  // useEffect(() => {
  //   const images = document.querySelectorAll(".banner-col2 video");
  //   let currentIndex = 0;

  //   const changeImage = () => {
  //     images[currentIndex].classList.remove("active");
  //     images[currentIndex].style.display = "none";
  //     currentIndex = (currentIndex + 1) % images.length;
  //     images[currentIndex].classList.add("active");
  //     images[currentIndex].style.display = "block";
  //   };

  //   const interval = setInterval(changeImage, 5000); // 5000ms = 5s

  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, []);

  // useEffect(() => {
  //   const images = document.querySelectorAll(".banner-col2 video");
  //   let currentIndex = 0;

  //   const changeImage = () => {
  //     images[currentIndex].classList.remove("active");
  //     images[currentIndex].style.display = "none";
  //     currentIndex = (currentIndex + 1) % images.length;
  //     images[currentIndex].classList.add("active");
  //     images[currentIndex].style.display = "block";
  //   };

  //   const interval = setInterval(changeImage, 5000); // 5000ms = 5s

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const bannerCol1 = document.querySelector(".banner-col1");
      const bannerCol3 = document.querySelector(".banner-col3");
      const img1 = document.querySelector(".logo1");
      const img2 = document.querySelector(".logo2");

      if (window.scrollY > 1000) {
        bannerCol1.classList.add("sticky");
        bannerCol3.classList.add("sticky");
        img1.style.display = "block";
        img2.style.display = "block";
      } else {
        bannerCol1.classList.remove("sticky");
        bannerCol3.classList.remove("sticky");
        img1.style.display = "none";
        img2.style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="banner-wrapper">
      <div className="banner-col1">
        <img src={popup1} alt="logo1" className="logo1" />
      </div>
      <div className="banner-col2">
        <video
          src={vid1}
          autoPlay
          muted
          loop
          className="banner-vid active"
          style={{ display: "block" }}
        />
        {/* <video
          src={vid2}
          autoPlay
          muted
          loop
          className="banner-vid"
          style={{ display: "none" }} // Ẩn video cho đến khi người dùng nhấp vào hình ảnh
        />
        <video
          src={vid3}
          autoPlay
          muted
          loop
          className="banner-vid"
          style={{ display: "none" }} // Ẩn video cho đến khi người dùng nhấp vào hình ảnh
        /> */}
        {/* <img src={banner1} alt="banner1" className="banner active" />
        <img src={banner2} alt="banner2" className="banner" />
        <img src={banner3} alt="banner3" className="banner" /> */}
      </div>
      <div className="banner-col3">
        <img src={popup2} alt="logo2" className="logo2" />
      </div>
    </div>
  );
};

export default Banner;
