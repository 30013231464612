import "./modalVideo.css";

const ModalVideo = ({ videoUrl, closeModal }) => {
  const handleOutsideClick = (e) => {
    if (e.target.className === "modal open") {
      closeModal();
    }
  };

  return (
    <div>
      <div
        className={`modal ${videoUrl ? "open" : ""}`}
        onClick={handleOutsideClick}
      >
        <div className="modal-content">
          <button className="close-button" onClick={closeModal}>
            x
          </button>
          {videoUrl && (
            <video
              src={videoUrl}
              controls
              autoplay={videoUrl ? "autoplay" : undefined}
              loop
              preload="none"
              className="youtube"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalVideo;
