import "./header.css";
import logo from "../asset/img/logo-nobg.webp";

const Header = () => {
  return (
    <div className="Header">
      <div className="header-container">
        <div className="header-leftside">
          <img className="header-logo" src={logo} />
        </div>
        <div className="slogan">
          SHBET88 TRỞ THÀNH ĐỐI TÁC CHIẾN LƯỢC CỦA HỆ THỐNG 500AE
        </div>
        <div className="header-rightside">
          <div className="group-buttons">
            <a href="https://sh059.com/?f=3689649" className="icon1">
              ĐĂNG KÝ
            </a>
            <a href="https://sh059.com" className="icon1">
              ĐĂNG NHẬP
            </a>
          </div>
          <div className="hotline">HOTLINE: 070. 826. 9999</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
