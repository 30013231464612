import "./footer.css";
import license from "../asset/img/licensePC.webp";
import base from "../asset/img/basePC.webp";
import socialtele from "../asset/img/telePC.webp";
import socialfb from "../asset/img/fbPC.webp";
import socialyt from "../asset/img/ytPC.webp";
import socialtt from "../asset/img/ttPC.webp";
import vectorFoot from "../asset/img/vector-foot.webp";
import logoFoot from "../asset/img/logo500aePC.webp";
import payment from "../asset/img/banksPC.webp";
import guarantee from "../asset/img/bewarePC.webp";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-row1">
          <div className="item-row1">
            <div className="text-wrapper-12">Giấy Phép Hợp Tác</div>
            <img className="license" src={license} />
          </div>
          <div className="item-row1">
            <div className="text-wrapper-12">Gia Nhập Nền Tảng</div>
            <img className="base" src={base} />
          </div>
          <div className="item-row1">
            <div className="text-wrapper-12">Mạng Xã Hội</div>
            <div className="social">
              <a href="https://t.me/trolytong500AE1" className="social">
                <img className="socialTele" src={socialtele} />
              </a>
              <a
                href="https://www.facebook.com/Hethong500ae1/"
                className="social"
              >
                <img className="socialFb" src={socialfb} />
              </a>
              <a href="https:www.youtube.com/@Hethong500ae" className="social">
                <img className="socialYt" src={socialyt} />
              </a>
              <a
                href="https://www.tiktok.com/@congdongdautu500ae"
                className="social"
              >
                <img className="socialTt" src={socialtt} />
              </a>
            </div>
          </div>
          <div className="item-row1">
            <img className="vectorFoot" src={vectorFoot} />
          </div>
          <div className="item-row1">
            <img className="logoFoot" src={logoFoot} />
          </div>
        </div>
        <div className="footer-row2">
          <div className="item-row21">
            <div className="text-wrapper-12">Phương Thức Thanh Toán</div>
            <img className="payment" src={payment} />
          </div>
          <div className="item-row21">
            <div className="text-wrapper-12">Trách nhiệm uy tín & an toàn</div>
            <img className="guarantee" src={guarantee} />
          </div>
        </div>
        <div className="footer-row3">
          <div className="group-34">
            <div className="footer-link-col1">
              <div className="text-wrapper-18">Về Chúng Tôi </div>
              <div className="text-wrapper-18">Chơi Có Trách Nhiệm </div>
              <div className="text-wrapper-18">Quyền Riêng Tư </div>
            </div>
            <div className="footer-breaker">
              </div>
            <div className="footer-link-col2">
              <div className="text-wrapper-18">Điều Khoản Và Điều Kiện</div>
              <div className="text-wrapper-18">Liên Hệ Chúng Tôi</div>
              <div className="text-wrapper-18">Hợp Tác</div>
            </div>
          </div>
        </div>
        <div className="footer-row4">
          <div className="text-wrapper-18">
            © 2024 Mọi Bản Quyền Thuộc Về Shbet v.1.0.3
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
