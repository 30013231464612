import "./boss.css";
import bossImg1 from "../asset/img/boss1cn1.webp";
import bossImg2 from "../asset/img/boss2cn1.webp";
import bossImg3 from "../asset/img/boss3cn1.webp";
import bossImg4 from "../asset/img/boss1cn2.webp";
import bossImg5 from "../asset/img/boss2cn2.webp";
import bossImg6 from "../asset/img/boss3cn2.webp";
import bossImg7 from "../asset/img/boss4cn2.webp";
import bossImg8 from "../asset/img/boss5cn2.webp";
import bossImg9 from "../asset/img/boss6cn2.webp";
import bossImg10 from "../asset/img/boss7cn2.webp";
import bossImg11 from "../asset/img/boss8cn2.webp";
import bossImg12 from "../asset/img/boss9cn2.webp";
import bossImg13 from "../asset/img/boss10cn2.webp";

import bossImg14 from "../asset/img/boss1cn3.webp";
import bossImg15 from "../asset/img/boss1cn4.webp";
import bossImg16 from "../asset/img/boss1cn5.webp";

import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Boss = () => {
  const addStyle = () => {
    return { display: "none" };
  };

  useEffect(() => {
    let prev = document.querySelector(".bossprev");
    let next = document.querySelector(".bossnext");
    let bossBox = document.querySelector(".bossbox");

    next.addEventListener("click", function () {
      let items = document.querySelectorAll(".bossitem");
      let firstItem = items[0];

      // Thêm lớp để kích hoạt hiệu ứng di chuyển
      firstItem.classList.add("moving-out");

      // Chờ cho hiệu ứng kết thúc trước khi chuyển phần tử về cuối danh sách
      setTimeout(() => {
        firstItem.classList.remove("moving-out"); // Loại bỏ lớp sau khi hiệu ứng kết thúc
        document.querySelector(".bossbox").appendChild(firstItem); // Chuyển phần tử về cuối
      }, 500); // Thời gian phải khớp với thời gian trong `transition` CSS (ở đây là 0.5s)
    });

    prev.addEventListener("click", function () {
      let items = document.querySelectorAll(".bossitem");
      let lastItem = items[items.length - 1];

      // Thêm lớp để kích hoạt hiệu ứng di chuyển
      lastItem.classList.add("moving-out");

      // Chờ cho hiệu ứng kết thúc trước khi chuyển phần tử về đầu danh sách
      setTimeout(() => {
        lastItem.classList.remove("moving-out"); // Loại bỏ lớp sau khi hiệu ứng kết thúc
        document.querySelector(".bossbox").prepend(lastItem); // Chuyển phần tử về đầu
      }, 500); // Thời gian phải khớp với thời gian trong `transition` CSS (ở đây là 0.5s)
    });

    // Tạm dừng tự động xoay khi hover
    document.querySelector(".bossbox").addEventListener("mouseover", () => {
      clearInterval(autoPlay);
    });

    document.querySelector(".bossbox").addEventListener("mouseout", () => {
      autoPlay = setInterval(() => {
        next.click();
      }, 3000);
    });

    // Swipe functionality
    let startX;
    let endX;

    bossBox.addEventListener("touchstart", (e) => {
      startX = e.touches[0].clientX;
    });

    bossBox.addEventListener("touchmove", (e) => {
      endX = e.touches[0].clientX;
    });

    bossBox.addEventListener("touchend", () => {
      if (startX > endX + 50) {
        next.click();
      } else if (startX < endX - 50) {
        prev.click();
      }
    });

    // Auto-play functionality
    let autoPlay = setInterval(() => {
      next.click();
    }, 3000);

    addStyle();
  }, []);
  return (
    <div className="bossContainer">
      <div className="bossbox">
        <div className="bossitem item1">
          <img src={bossImg1} />
        </div>
        <div className="bossitem item2">
          <img src={bossImg2} />
        </div>
        <div className="bossitem item3">
          <img src={bossImg3} />
        </div>
        <div className="bossitem item4">
          <img src={bossImg4} />
        </div>
        <div className="bossitem item5">
          <img src={bossImg5} />
        </div>
        <div className="bossitem item6">
          <img src={bossImg6} />
        </div>
        <div className="bossitem item7">
          <img src={bossImg7} />
        </div>
        <div className="bossitem item8">
          <img src={bossImg8} />
        </div>
        <div className="bossitem item9">
          <img src={bossImg9} />
        </div>
        <div className="bossitem item10">
          <img src={bossImg10} />
        </div>
        <div className="bossitem item11">
          <img src={bossImg11} />
        </div>
        <div className="bossitem item12">
          <img src={bossImg12} />
        </div>
        <div className="bossitem item13">
          <img src={bossImg13} />
        </div>
        <div className="bossitem item14">
          <img src={bossImg14} />
        </div>
        <div className="bossitem item15">
          <img src={bossImg15} />
        </div>
        <div className="bossitem item16">
          <img src={bossImg16} />
        </div>
        <div className="bossbuttons">
        <span className="bossprev">
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
        <span className="bossnext">
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </div>
      </div>
      
    </div>
  );
};

export default Boss;
