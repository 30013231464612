import "./data.css";
import plus50 from "../asset/img/line-50plus.webp";
import plus100 from "../asset/img/line-100plus.webp";
import plus500 from "../asset/img/line-500plus.webp";
import element21 from "../asset/img/element2-1.webp";
import React, { useState, useEffect } from "react";

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Data = () => {
  const [currentMoney, setCurrentMoney] = useState(50);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    const updateMoney = () => {
      let currentMoney = 50;
      let increment = getRandomNumber(0, 5);
      let newMoney = currentMoney + increment;

      let step = (newMoney - currentMoney) / 20;
      let counter = 0;
      let interval = setInterval(() => {
        setCurrentMoney((prevMoney) => prevMoney + step);
        counter++;
        if (counter >= 20) {
          clearInterval(interval);
          setCurrentMoney(newMoney);
        }
      }, 25);

      setHighlight(true);
      setTimeout(() => {
        setHighlight(false);
      }, 500);
    };

    const intervalId = setInterval(updateMoney, getRandomNumber(4000, 5000));
    return () => clearInterval(intervalId);
  }, [currentMoney]);

  return (
    <div className="data">
      <div className="data-container">
        <div className="dataItem">
          <div className="title-content">
            <h1>HÀNH TRÌNH 10 NĂM VÀ NHỮNG CON SỐ ĐÁNG TỰ HÀO </h1>
          </div>
          <div className="body-content">
            <div className="handImg">
              <img className="element21" src={element21} alt="element21" />
            </div>
            <div className="plusImg">
              <div className="plus50">
                <div
                  className={`plus50-num money ${highlight ? "highlight" : ""}`}
                >
                  +500
                </div>
                <img className="plus50Img" src={plus50} alt="plus50" />
                <div className="plus50-text">Chuyên gia</div>
              </div>
              <div className="plus100">
                <div
                  className={`plus50-num money ${highlight ? "highlight" : ""}`}
                >
                  +1000
                </div>
                <img className="plus50Img" src={plus100} alt="plus100" />
                <div className="plus50-text">
                  Chương trình đầu tư thành công
                </div>
              </div>
              <div className="plus500">
                <div
                  className={`plus50-num money ${highlight ? "highlight" : ""}`}
                >
                  +8M
                </div>
                <img className="plus50Img" src={plus500} alt="plus150" />
                <div className="plus50-text">Nhà đầu tư thành công</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data;
