import "./serverSelect.css";
import btn from "../asset/img/bnt1-1.webp";

const ServerSelect = () => {
  const hiddenStyle = { display: "none" };
  return (
    <div className="server-select">
      <div className="title-server">
        <img className="btn-sv" src={btn} alt="" />
        <span>Chọn chi nhánh để tham gia</span>
      </div>
      <div className="server-list">
        <div className="col1">
          <a className="link2" href="https://t.me/trolytong500AE1">
            <span className="main-content">CHI NHÁNH 1</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/TROLYTONG500AE">
            <span className="main-content">CHI NHÁNH 2</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/TROLYTONG_500AE">
            <span className="main-content">CHI NHÁNH 3</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/TROLY500AEGR">
            <span className="main-content">CHI NHÁNH 4</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/TROLYTONG500ANHEM">
            <span className="main-content">CHI NHÁNH 5</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/TROLYTONG500AE_CN6">
            <span className="main-content">CHI NHÁNH 6</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/TROLYTONG500A_CN7">
            <span className="main-content">CHI NHÁNH 7</span>
            <span className="truycap">Truy cập</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServerSelect;
