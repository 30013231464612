import "./video.css";
import union from "../asset/img/union.svg";
import ok1 from "../asset/vid/1.gif";
import ok2 from "../asset/vid/2.gif";
import ok3 from "../asset/vid/3.gif";
import ok4 from "../asset/vid/4.gif";

const Subject = () => {
  return (
    <div className="Video">
      <div className="video-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">KHUYẾN MÃI KHỦNG TỪ SHBET88 - HỆ THỐNG 500AE</span>
        </div>
        <div class="video-body">
          <div className="videoItems">
            <div className="video-row1">
              <a class="video-img">
                <img src={ok1} />
                <div className="title-video">
                3 NGÀY PHÁT LƯƠNG CÙNG HỆ THỐNG 500AE - SHBET
              </div>
              </a>
              <a class="video-img">
                <img src={ok2} />
                <div className="title-video">
                SỰ KIỆN NGÀY VÀNG CÙNG HỆ THỐNG 500AE - SHBET
              </div>
              </a>
              <a class="video-img">
                <img src={ok3} />
                <div className="title-video">
                NHẬN 57K MIỄN PHÍ CHO THÀNH VIÊN MỚI CÙNG HỆ THỐNG 500AE - SHBET
              </div>
              </a>
              <a class="video-img">
                <img src={ok4} />
                <div className="title-video">
                NẠP ĐẦU NHẬN 8.888K CÙNG HỆ THỐNG 500AE - SHBET
              </div>
              </a>
            </div>           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subject;
