import "./modalImg.css";

const ModalVideo = ({ videoUrl, closeModal }) => {
  const handleOutsideClick = (e) => {
    if (e.target.className === "modal open") {
      closeModal();
    }
  };

  return (
    <div>
      <div
        className={`modal ${videoUrl ? "open" : ""}`}
        onClick={handleOutsideClick}
      >
        <div className="modal-content">
          <button className="close-button" onClick={closeModal}>
            x
          </button>
          {videoUrl && (
            <img
              src={videoUrl}
              className="img-modal"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalVideo;
