import "./subject.css";
import union from "../asset/img/union.svg";
import subject from "../asset/img/subject.webp";

const Subject = () => {
  return (
    <div className="Subject">
      <div className="subject-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">
            MEGA LIVE BẢO HIỂM VỐN HỆ THỐNG 500AE - SHBET88
          </span>
        </div>
        <div className="subject-body">
          <div className="subject-item">
            {/* <video autoPlay loop controls muted className="vid-item">
              <source src={ok1}
              type="video/mp4"
              />
            </video>
            <video autoPlay loop controls muted className="vid-item">
              <source
                src={ok2}
                type="video/mp4"
                
              />
            </video> */}
            <div className="subject-item-title">
              <img className="subjectImg" src={subject} alt="subject" />
            </div>
            {/* <a className="subject-item-title" href="#">
              <img className="subjectImg" src={subjectItem} alt="subject" />
            </a>
            <a className="subject-item-title" href="#">
              <img className="subjectImg" src={subjectItem} alt="subject" />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subject;
