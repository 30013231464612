import "./feedback.css";
import union from "../asset/img/union.svg";
import { useState, useRef } from "react";
import ModalVideo from "../modalVideo/ModalVideo";
import vid1 from "../asset/vid/feedvid1.mp4";
import vid2 from "../asset/vid/feedvid2.mp4";
import vid3 from "../asset/vid/feedvid3.mov";
import vid4 from "../asset/vid/feedvid4.mp4";
import videoThumbnail1 from "../asset/img/feedbackImg1.webp";
import videoThumbnail2 from "../asset/img/feedbackImg2.webp";
import videoThumbnail3 from "../asset/img/feedbackImg3.webp";
import videoThumbnail4 from "../asset/img/feedbackImg4.webp";

const Feedback = () => {
  const videoFeedback1 = vid1;
  const videoFeedback2 = vid2;
  const videoFeedback3 = vid3;
  const videoFeedback4 = vid4;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleItemClick = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoUrl(""); // Reset videoUrl when closing modal
  };

  const videoRef = useRef(null);
  const imgRef = useRef(null);

  const handleVideoClick = () => {
    if (imgRef.current) {
      imgRef.current.style.display = "none"; // Ẩn hình ảnh đại diện
    }
    if (videoRef.current) {
      videoRef.current.style.display = "block"; // Hiển thị video
      videoRef.current.play(); // Phát video
    }
  };

  return (
    <div className="feedback">
      <div className="feedback-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">Feedback</span>
        </div>
        <div className="feedback-body">
          <div className="feedback-wrapper-body">
            <div className="feedback-col1">
              Cộng đồng boss uy tín 500AE giúp anh em sử dụng vốn đầu tư một
              cách hợp lý, giảm thiểu rủi ro. Với các boss giàu kinh nghiệm dẫn
              dắt trong suốt 9 năm, chúng tôi tự hào đã giúp cho hàng triệu nhà
              đầu tư ngày một phát triển.
            </div>
            <div className="feedback-col2">
              <div
                className="item"
                onClick={() => handleItemClick(videoFeedback1)}
              >
                <div className="title-feedback">
                  <img src={videoThumbnail1} alt="videoImg1" />
                </div>
              </div>
              <div
                className="item"
                onClick={() => handleItemClick(videoFeedback2)}
              >
                <div className="title-feedback">
                  <img src={videoThumbnail2} alt="videoImg1" />
                </div>
              </div>
              <div
                className="item"
                onClick={() => handleItemClick(videoFeedback3)}
              >
                <div className="title-feedback">
                  <img src={videoThumbnail3} alt="videoImg1" />
                </div>
              </div>
              <div
                className="item"
                onClick={() => handleItemClick(videoFeedback4)}
              >
                <div className="title-feedback">
                  <img src={videoThumbnail4} alt="videoImg1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen &&
          (console.log("Video URL:", videoUrl),
          (<ModalVideo videoUrl={videoUrl} closeModal={closeModal} />))}
      </div>
    </div>
  );
};

export default Feedback;
