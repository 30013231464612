import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./carousel.css";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";

import slide_image_1 from "../asset/coupon/dt1.webp";
import slide_image_2 from "../asset/coupon/dt2.webp";
import slide_image_3 from "../asset/coupon/dt3.webp";
import slide_image_4 from "../asset/coupon/dt4.webp";
import slide_image_5 from "../asset/coupon/dt5.webp";
import slide_image_6 from "../asset/coupon/dt6.webp";
import slide_image_7 from "../asset/coupon/dt7.webp";
import slide_image_8 from "../asset/coupon/dt8.webp";
import slide_image_9 from "../asset/coupon/dt9.webp";
import slide_image_10 from "../asset/coupon/dt10.webp";
import slide_image_11 from "../asset/coupon/dt11.webp";
import slide_image_12 from "../asset/coupon/dt12.webp";
import slide_image_13 from "../asset/coupon/dt13.webp";

const Carousel = () => {
  return (
    <div className="carousel-container">
      <Swiper
        effect={"slide"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={3}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ clickable: true }}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_8} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_9} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_10} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_11} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_12} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_13} alt="slide_image" className="dtImg" />
        </SwiperSlide>
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
};

export default Carousel;
